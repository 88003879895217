/**
 * IMPORT FONTS
 */
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import '~noty/src/noty.scss';
@import '~noty/src/themes/mint.scss';

@import url('https://fonts.googleapis.com/css?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css');

@import '_variables';

// override the theme for toast notifications
.swal2-toast-shown {
    .swal2-container.swal2-center {
        top: 5% !important;
        width: 100% !important;
        padding: 0 0 2px !important;

        .swal2-popup.swal2-toast {
            padding: 1rem;
        }

        .swal2-popup.swal2-toast.swal2-show {

            width: 100% !important;
            color: #fff !important;
            box-shadow: none !important;
            border-radius: 0 !important;
            font-size: 1.2rem !important;
        }

        .swal2-content .swal2-html-container{
            p {
                color: #fff !important;
            }
        }

        .swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=right],
        .swal2-popup.swal2-toast .swal2-success .swal2-success-fix,
        .swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=left] {
            background-color: transparent !important;
            display: none !important;
        }

        .swal2-timer-progress-bar {
            background: #fff !important;
        }

        .swal2-popup.swal2-toast .swal2-success,
        .swal2-popup.swal2-toast .swal2-error {
            margin-right: 1rem !important;
        }

        .swal2-toast.swal2-icon-success {
            .swal2-styled.swal2-cancel {
                margin: 0 1rem !important;
                background: #ebfcef !important;
                color: #00A081 !important;
            }
        }

        .swal2-toast.swal2-icon-error {
            .swal2-styled.swal2-cancel {
                margin: 0 1rem !important;
                background: #ebfcef !important;
                color: #F2746A !important;
            }
        }

        .swal2-icon.swal2-success [class^=swal2-success-line],
        .swal2-icon.swal2-success [class^=swal2-success-line][class$=long],
        .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
            background-color: #ebfcef !important;
        }

        .swal2-icon.swal2-success .swal2-success-ring {
            border-color: #ebfcef !important;
        }

        .swal2-icon.swal2-error {
            margin: 0 1rem !important;
            border-color: #ebfcef;
            color: #ebfcef;
        }
    }
}

.swal2-container .swal2-popup {
  .swal2-success-line {
    &-tip, &-long {
      background-color: #00A081 !important;
    }
  }
  .swal2-success-ring {
    border-color: #00A081 !important;
  }
  .swal2-confirm {
    background-color: #4D50A2 !important;
  }
}

.vsm--item {
    .vsm--link {
        display: flex;
        flex-wrap: wrap;
    }
}

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.vsm--item {
    .vsm--link {
        display: flex;
        flex-wrap: wrap;
    }
}

html,
body.Site {
  background: $body-bg;
  background-color: $body-bg;
  height: 100%;
}

.notification-popup {
    overflow-y: scroll;
}

.notifications {
    position:relative;

    .open-notification-popup {
        margin-right: .85rem;
        position:relative;
        font-size: 1.75rem;

        .icon {
            font-size: 1.65rem;
            color: #747474;
        }

        .label {
            top: 5px;
            font-size: .65rem;
            padding: 6px 8px 5px;
            left: auto;
            right: -4px;
        }
    }

    .notification-popup {
        overflow: scroll;

        .header {
            display:flex;
            justify-content: space-between;
        }

        .items {
            max-height: 400px;
            height:auto;
            overflow-y: scroll;
            width: 403px;

            .item {
                align-items: flex-start;
                padding: 1.5rem 0;

                .content {

                    .label {
                        display: inline-block;
                        margin-bottom: 1rem;
                    }

                    .header {
                        display: block;
                    }
                    .description {
                        a {
                            color: #4D50A2;
                            font-weight: bold;
                        }
                    }
                }
            }
        } // .items
    }
} // .notifications

.ui.form {
    .dateError {
        input {
            border: 1px solid $salmonAlt !important;
        }
    }
    .dateSuccess {
        input {
            border: 1px solid $actionGreen !important;
        }
    }
} // .ui.form

.ui.form .field.field input:-webkit-autofill {
    &.changed-true {
        &.valid-true {
            border-color: #00A081 !important
        }
        &.valid-false {
            border-color: #F2746A !important
        }
    }
}

.app-content {
    &.request-stored-payment-method,
    &.grant-access-payment-method {


    #mobile-header {
        display: none;
    }

    .content-parent {
        width: 100%;
        padding: 0;
        margin-bottom: 3rem;

    }

    .ui {
        &.grid {
            .provider {
                padding-top: 3rem;
            }

            .column {
                &.provider-logo {
                    text-align:right;

                    @media (max-width: 767px) {
                        text-align: left;
                    }
                }

                @media (max-width: 767px) {
                    .big-titles {
                        font-size: 1.65rem !important;
                        line-height: 1.2;
                    }
                }
            }

            .content {
                p {
                   font-size: 1.1rem;
                    color: #72808E;
                }
            }
        }
    } // .ui

    .big-titles.largly {
        font-size: 3rem !important;
    }

    .big-titles.largly span {
        color: #00A081;
        cursor: pointer;
        text-decoration: underline;
    }

    .big-titles {
        font-size: 2.5rem !important;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: #1f4075;
        transition: all 0.25s ease-out;
    }

    .provider--info {
        color: #6D6D6D;
        line-height: 1;
        font-size: 14px;
        margin-top: 16px;

        p {
            margin-bottom: 4px;
        }

        &.tabletOnly {
            display: none !important;
            @media (min-width: 768px) {
                display: block !important;
            }
        }

        &.mobileOnly {
            display: none !important;

            @media (max-width: 767px) {
                display: block !important;
                margin-top: 20px;

                p {
                    margin-bottom: 0;
                }
            }
        }
    } // .provider--info

    .poweredBy {
        span {
            font-size: 13px;
            color: #bbb;
            display: inline-block;
            margin-right: 6px;
        }

        img {
            max-width: 140px;
        }
    } // .poweredBy
    }

    &.react_collect_payment{

      ::-webkit-scrollbar{
        width: auto;
      }

      ::-webkit-scrollbar-track{
        border-radius: 5px;
      }
    }
} // .app-content

.alerts {
    margin-bottom: 2rem;

    .ui.error.message {
        align-items: flex-start;

        .icon {
            font-size: 1.75rem;
        }
    }
} // .alerts


/**
 * MODALS
 */
.ui {
  &.dimmer {
    &.modals {
      justify-content: flex-start;
    }
  }

  &.modal {
    @media only screen and (min-width: 1200px) {
      width: 85vw;
    }

    .ui {
      &.clearing {
        &.segment {
          border-radius: 8px 8px 0 0;
          background: #fff;
          margin: 0 0 1rem 0;
          padding: 1rem;
          display: flex;
          align-items: center;

          h3 {
            text-transform: uppercase;

            &.floated {
              width: 50%;
              margin-bottom: 0;

              &.status {
                color: $salmon;
                display: flex;
                i {
                  font-size: 14px;
                }
              }

              &.right {
                &.header {
                  display: flex;
                  justify-content: flex-end;;
                }
              }
            }

            .invoiceNumber {
              text-transform: none;
              font-weight: 600;
              color: $text;
            }
          }
        }
      }
    } // .ui

    .button-row {
      max-width: 80vw;
      width: 100%;
      padding-bottom: 25px;
      margin-bottom: 2rem;

      .column {
        margin-bottom: 20px;
      }
    } // .button-row

    .content {
      .container {
        max-height: 80vh;
        max-width: 98%;
        width: 98%;
        padding-top: 25px;
      }
    }
    > .content {
      overflow: auto;
      margin-bottom: 3rem;
    }

    .dueDate {
      display:flex;
      align-items: center;
      margin-bottom: 1px;
      .label {
        margin-left: 5px;
        padding: 6px 12px 4px;
        letter-spacing: .75px;
      }
    } // .dueDate

    .column--info {
      .row--data {
        background: #fff;
        padding: 20px 10px;
        border-radius: 8px;

        &:first-child {
          margin-top: 0;
        }

        &.row--header {
          margin-top: 2.5rem;

          &:first-child {
            margin-top: 0;
          }
        }
      } // .row--data
      .details-section {
        display: flex;
        align-items: center;

        .payment-link {
          white-space: nowrap;
        }
      }
    } // .column--info

    .sidebar {
      height: calc(100% - 2rem);

      .row--data,
      .row--header {
        &:first-child {
          margin-top: 0;
        }
      }

      .column {
        margin-bottom: 2rem;
      }
    } // .sidebar
  } // .ui.modal

  .row--data {
    margin-top: 2rem;

    h5 {
      margin-bottom: .25rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    &.row--header {
      margin-top: 4rem;
      margin-bottom: 2rem;

      h4 {
          width: 100%;
          padding-left: 0;
          font-weight: bold;
          margin-bottom: 0;
          text-transform: uppercase;
          font-size: 1.2rem;
          line-height: 1.8;
      }

      hr {
          padding: 0;
          height: 3px;
          background: #eee;
          border: none;
          margin: 10px 0 2.5rem;
          width: 100%;
          display: block;
      }
    }

    .item_cost {
      h5 {
        font-weight: normal;
        color: $text;
      }

      .total {
        h5 {
          font-weight: bold;
          color: $heading
        }
      }
    } // .item_cost
  }
}

.user-identifier {
    .field {
        &.flex-label {
            h3 {
                margin: 0 2rem 3px 0;
                font-size: 1rem;
                color: rgba(0,0,0,.45);
            }
        }

        .icon {
            color: rgba(0,0,0,.2);
        }
    }
} // .user-identifier

/**
 * FONTS
 */
ui:not(.react) p {
    color: $text;
}

.ui b, strong {
  font-size: inherit !important;
}

.ui h1, h2, h3, h4, h5 {
  color: $heading;
}

a {
  cursor: pointer;
  color: inherit;
}

.ui .section-title {
  color: theme-color('highlight');
  text-transform: uppercase;
  font-size: 18px;
}

.ui .section-subtitle {
  color: theme-color('gray-dark')
}

/**
  * HELPERS
  */
.no-bottom-margin {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0 !important;
}

i {
  &.bordered {
    &.icon {
      box-shadow: none;
    }
  }
}

img {
  max-width: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-align {
  display: flex;
  align-items: center;
}

.flex {
  display: flex
}

.flex-jc {
  justify-content: center;
}
.flex-ac {
  align-items: center;
}
.flex-end {
  justify-content: flex-end;
  display: flex;
}
.flex-start {
  justify-content: flex-start;
  display: flex;
}
.position-relative {
  position: relative;
}
.full {
  width: 100%;
}
.font-bold {
  font-weight: 700;
}

.float-right {
    float: right;
}
.float-left {
    float:left
}

.icon-right {
    margin-left: 4px;
}
.icon-left {
    margin-right: 4px;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before,
.ui.toggle.checkbox input {
  background-color: $actionGreen !important;
}
.ui.toggle.checkbox label,
.ui.toggle.checkbox input:focus:checked ~ .box,
.ui.toggle.checkbox input:focus:checked ~ label {
  color: #72808e;
}




.vue-form-wizard {
    position: relative !important;
    top: -7px !important;

    .wizard-header {
      display: none !important;
      height: 0 !important;
      padding: 0 !important;
    }

    .wizard-icon-container {
      &.tab_shape {
        height: 30px !important;
        align-items: center !important;
      }
    }

    .wizard-tab-content {
      padding-top: 3rem !important;
    }

    .wizard-icon-circle {
      &.tab_shape {
        align-items: center !important;
      }

      &:focus,
      &:active,
      &:focus:active {
        border-color: none !important;
        outline: 0 !important;
        box-shadow: none !important;
      }
    }

    .stepTitle {
      margin-top: 3px !important;
    }

    &.autoRecon {
      .Patient {
        a {
          &:hover {
            cursor: inherit !important;
          }
        }
      }
    }

    .Patient {
      .wizard-icon-container {
        &.tab_shape {
          border-radius: 8px 0 0 0 !important;
        }
      }
    }

    .Receipt {
      .wizard-icon-container {
        &.tab_shape {
          border-radius: 8 8px 0 0 !important;
        }
      }
    }
} // .vue-form-wizard

/* DASHBOARD PAGE */
#theRecentCompletedInvoices {
    .amount-label {
        font-size: 1rem;
    }
    th {
        padding-left: .25rem;
    }
}

.dash-amount {
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    color: #414141;
}

.dash-percent {
    font-size: 16px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: 0.2px;
    color: #00A081;
}

.dash-timeframe {
    font-size: 12px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: 0.8px;
    text-align: right;
    color: #72808e;
}


.statement-date {
    font-size: 1.10rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.7;
    letter-spacing: 0.8px;
    text-align: center;
    color: $lightGray;
}

.statement-date.month {
    text-transform: uppercase;
    font-size: .85rem;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.6px;
    text-align: center;
    color: #72808e;
}

/* SETTINGS PAGE */
.ui.secondary.vertical.pointing.menu .active.item {
  border-color: transparent;
}

/**
 * Override Date Picker
 */
.mx-datepicker {
    width: 100% !important;

    input {
        &::placeholder {
            color: rgba(0,0,0,1);
        }
    }

    .mx-datepicker-main {
        &.mx-datepicker-popup {
            height: 380px !important;

            .mx-datepicker-sidebar {
                .mx-btn-shortcut {
                    margin-bottom: .75rem !important;
                }
            }
        }
    }

    .mx-icon-calendar {
        &:hover {
            cursor: pointer;
        }
    }
} // .mx-datepicker

/**
  * FORMS
  */
  .ui.form .field > label {
    font-size: .85rem !important;
    color: $grayAlt !important;
  }

.ui.form {
  input {
    &.changed-true {
      &.valid-true {
        border: 1px solid $actionGreen !important;
      }

      &.valid-false {
        border: 1px solid $salmon !important;

        + label:before {
          border: 1px solid $salmon !important;
        }
      }
    }

    &[type="checkbox"] {
      &.dirty-true {
        &.valid-true {
          + label:before {
            border: 1px solid $actionGreen !important;
          }
        }

        &.valid-false {
          + label:before {
            border: 1px solid $salmon !important;
          }
        }
      }
    }

    + .error {
      color: $salmonAlt;
      font-weight: normal;
      font-size: 11px;
      margin-top: 2px;
      display: inline-block;
      letter-spacing: .75px;
    }
  } // input
  .password-type-input-container {
    .fa {
      font-size: 17px;
    }
    .fa-eye {
      width: 16px;
    }
    .fa-eye-slash {
      width: 17px;
    }
    + .error {
      color: $salmonAlt;
      font-weight: normal;
      font-size: 11px;
      margin-top: 2px;
      display: inline-block;
      letter-spacing: .75px;
    }
  }
} // .form

.fields {
  .field {
    margin-bottom: 2rem;
  }
}

// override semantic
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="time"],
.ui.form input[type="text"],
.ui.form input[type="file"],
.ui.form input[type="url"],
.ui.form textarea,
.ui.input > input {
  box-shadow: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  padding: 0.65rem 1rem;
}

.ui.message {
    color: #747474;
}

.ui.basic.green.buttons .button, .ui.basic.green.button {
    -webkit-box-shadow: 0px 0px 0px 1px #00A081 inset !important;
    box-shadow: 0px 0px 0px 1px #00A081 inset !important;
    color: #00A081 !important;

    &:hover {
        background: #ffffff !important;
        color: #00A081 !important;
    }

    &.small {
        padding-top: 1rem;
    }
}

.ck {
  .ck-editor__main {
    .ck-editor__editable {
      padding: 1rem;
    }
  }
}

.invoice-form {
  .ck {
    .ck-editor__main {
      .ck-editor__editable {
        min-height: 140px;
      }
    }
  }
}

.disclosure {
  color: $lightGray;
}

.ui {
  &.toggle {
    &.checkbox {
      display: flex;
      align-items: center;

      input[type=checkbox] + label {
          padding-top: 0;
          padding-bottom: 0;
          font-size: inherit !important;
          font-weight: normal !important;
      }
    }
  }

  &.form {
    .radio--row {
      display: flex !important;

      .field {
        margin: 0 10px;

        &:first-child {
          margin: 0 10px 0 0;
        }

        &:last-child {
          margin: 0 0 0 10px;
        }

        input[type="radio"] {
          position: absolute;
          opacity: 0;

          + .radio-label {
            cursor: pointer;
            padding: 6px;
            text-transform: uppercase;

            &:hover {
              color: $gray !important;
            }

            &:before {
              content: '';
              background: #dfdfdf;
              border-radius: 100%;
              display: inline-block;
              width: 18px;
              height: 18px;
              position: relative;
              top: 0;
              margin-right: 1em;
              vertical-align: top;
              cursor: pointer;
              text-align: center;
              transition: all 250ms ease;
            }
          }

          &:checked {
            + .radio-label {
              &:before {
                background-color: $actionGreen;
              }
            }
          }

          &:focus {
            + .radio-label {
              &:before {
                outline: none;
                border-color: $actionGreen;
              }
            }
          }
        }
      } // .field
    } // .radio--row

    .switch--wrapper {
      display: flex;
      align-items: center;

      label {
        margin: 0 6px;
        color: $gray;
      }

      input[type="checkbox"].switch{
        appearance: none;
        width: 60px;
        height: 25px;
        background: white;
        border-radius: 50px;
        position: relative;
        cursor: pointer;
        outline: none;
        transition: all .2s ease-in-out;

        &:after {
          position: absolute;
          content: "";
          width: 25px;
          height: 25px;
          top: -1px;
          border-radius: 50%;
          background: $actionGreen;
          transform: scale(.7);
          left: 0;
          transition: all .2s ease-in-out;
        }

        &:checked {
          &:after {
            left: calc(100% - 1.5em);
          }
        }
      }
    } // .switch--wrapper

    .field {
      &.flex-label {
        label {
          display: flex;
          align-items: center;

          small {
            margin: 0 6px;
          }
        }
      }
    } // .field
  }
}

.ui {
  &.segment {
    margin-top: 2rem;

    h3 {
        margin: 10px 15px 30px;
        color: #72808e;
    }

    .flex-align {
      h3 {
        margin: 0 15px;
      }
    }
  }

  &.divider {
    margin: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: calc(100% - 10px);
    border: none !important;
    color: #72808e;
    font-size: 11px;
  }

  &.form {
    section {
      margin: 3rem 0;
    }

    .ui.green.message {
      border: #00A081;
      background: #ebfcef;

      label {
        margin-right: 3rem;
      }
    }

    label {
      text-transform: uppercase;
    }

    input {
      box-shadow: none !important;
      border: 1px solid #bbbbbb !important;
      font-size: 1.15rem;
      letter-spacing: 1.2;

      &.input-danger {
        border: 1px solid #f9736a !important;

        &:focus {
          border: 1px solid #dfdfdf !important;
        }
      }
    }

    .dollar {
      display: flex;
      align-items: center;
      font-size: 25px;
      width: auto;
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }
} // .ui

.fields {
  &.paymentType {
    display: flex;
    margin: 30px 0;

    &.message {
      padding: 30px 0;
    }

    .field {
      margin: 0 20px;
    }
  }
} // .fields

.export-me {
  margin-left: 1em !important;
}

/**
  * TABLES
  */

.pagination {
    position: relative;
    button {
        &:hover {
            cursor: pointer;
        }
    }
}

.ui.sortable.table thead th {
  cursor: pointer;
  white-space: nowrap;
  border-left: none !important;
  color: rgba(0, 0, 0, 0.87);
}

.ui.table {
  border-collapse:separate !important;
  border-spacing: 0 1em !important;
}

tr td:first-child { border-top-left-radius: 8px;border-bottom-left-radius: 8px; }
tr td:last-child { border-top-right-radius: 8px;border-bottom-right-radius: 8px; }

table tbody tr {
  cursor: pointer !important;
}

tr.clickable.ui.segment.raised:hover {
  cursor: pointer !important;
  background-color: rgba(214, 213, 213, 0.65);
}

.ui.raised.segment.top-align {
    vertical-align: top;
}

#view_daily_payments,
#view_weekly_payments {
    .ui.basic.table tbody tr,
    .ui.basic.table tbody td,
    table tbody tr,
    table tbody td {
        cursor: inherit !important;
        &:hover {
            cursor: inherit !important;
        }
    }
}

#theRecentCompletedInvoices,
#customer_home_recent,
#customer_dash_invoice {
    .ui.basic.table tbody tr,
    .ui.basic.table tbody td,
    table tbody tr,
    table tbody td {
        cursor: inherit !important;
        &:hover {
            cursor: inherit !important;
        }
    }
}

#theRecentCompletedInvoices {
    .ui.basic.table {
        border-spacing: 0 !important;

        tbody tr,
        tbody td,
        table tbody tr,
        table tbody td {
            padding: .25rem !important;
            border-radius: 0 !important;
        }
    }
}

.computer.only {
  display: table-cell;
}

.computer.only.text {
  display: inherit;
}

.ui.raised.segments, .ui.raised.segment {
  box-shadow: none !important;
}

.overflow-td-1 {
  td:first-child {
    overflow: visible !important;
  }
}

overflow-td-2 {
  td:nth-child(2) {
    overflow: visible !important;
  }
}

.ui.raised.segment td {
  padding: 1.5em 1em !important;
}

@media screen and (max-width: 1024px){
  .computer.only, .computer.only.text {
      display: none;
  }
}

.ui.modal {
  background: #f7f7f7;
}

.ui {
    &.table {
        td {
            color: $text
        }

        .vgt-right-align {
            text-align: right;
        }

        &.itemization {
            margin: 3.5rem 0 2rem;
            border-bottom: 1px solid #eee;
            border-radius: 0;

            .striped {
                background-color: rgba(0, 0, 50, 0.02);
            }

            td {
                border-radius: 0;
                border: none;
            }
        }

        &.borderless {
            th {
                padding: 0 .5rem;
            }
        }

        &.vgt-table th.sortable:before {
            top: 33%;
            left: 11px;
        }
        &.vgt-table th.sortable:after {
            top: 26%;
            left: 11px;
        }

        &.vgt-table th.sortable {
            padding-left: 27px;

            &:hover {
                cursor: pointer;
            }
        }

        &.vgt-table tr:first-child th {
            padding-left: 27px !important;
        }



    }
}

.ui[class*="very basic"].table:not(.sortable):not(.striped) th:first-child,
.ui[class*="very basic"].table:not(.sortable):not(.striped) td:first-child {
    padding-left: 11px;
}

.ui.collapsing.table {
    width: 100% ;
}

/**
 * BUTTONS
 */
#mobile-header {
    .ui {
        &.primary {
            &.button {
                &:visited {
                     color: #fff !important;
                }

                &:hover,
                &:focus {
                    background: #fff;
                    color: $cornblue !important;

                    a {
                        color: $cornblue;
                        background: transparent;
                    }
                }
            }
        }

        &.dropdown {
            &.dropdown-me {
                .item {
                    &.active {
                        background: $green !important;
                        color: #fff !important;
                        border-bottom: none !important;
                    }
                }
            }
        }
    } // .ui

    .dropdown-news {
        margin: 0 20px 0 0;
        position: relative;
        top: 10px;

        .text {
            position: relative;
            height: 35px;
            display: flex;
            align-items: center;

            .icon {
                font-size: 1.85rem;
                z-index: 1000;
            }

            .label {
                position: relative;
                left: -10px;
                top: -16px;
                font-size: .65rem;
            }
        }
    } // .dropdown-news
} // #mobile-header

.ui {
  &.button {
    border-radius: 5px;
    padding: 1rem 1.5rem .75rem 1.5rem;
    text-transform: uppercase;

    & > .icon:not(.button) {
      opacity: 1;
    }
  }

  // PRIMARY BUTTON COLOR
  &.primary {
    // normal primary button
    &.buttons {
      .button {
        background: $cornblue;
        color: #fff;
        border: 1px solid;
        box-shadow: none !important;
        text-transform: uppercase;

        &:hover,
        &:focus {
          background: #fff;
          color: $cornblue;

          a {
            color: $cornblue;
            background: transparent;
          }
        }

        a {
          &:hover,
          &:focus,
          &:visited {
            background: transparent;
          }
        }
      } // .button
    }

    &.button {
        background: $cornblue;
        color: #fff;
        border: 1px solid;
        box-shadow: none !important;
        text-transform: uppercase;

        &.dashed {
            background: #fff;
            color: $lightGray;
            border: 1px dashed $lightGray;
            width: 100%;
        }

        &:hover,
        &:focus {
            background: #fff;
            color: $cornblue;

            a {
              color: $cornblue;
              background: transparent;
            }
        }

        a {
            &:hover,
            &:focus {
              background: transparent;
            }
        }
    }

    // inverted primary button (white background)
    &.inverted {
      &.buttons {
        .button {
          background: #fff;
          color: $cornblue;
          border: 1px solid $cornblue;
          box-shadow: none !important;
          text-transform: uppercase;

          &:hover,
          &:focus {
            background: $cornblue;
            color: #fff;

            a {
              color: #fff;
              background: transparent;
            }
          }

          a {
            &:hover,
            &:focus {
              background: transparent;
            }
          }
        }
      }

      &.button {
        background: #fff;
        color: $cornblue;
        border: 1px solid $cornblue;
        box-shadow: none !important;
        text-transform: uppercase;

        &:hover,
        &:focus {
          background: $cornblue;
          color: #fff;

          a {
            color: #fff;
            background: transparent;
          }
        }

        a {
          &:hover,
          &:focus {
            background: transparent;
          }
        }
      }
    }
  }

  // SALMON COLORED BUTTONS
  &.red {
    &.buttons {
      .button {
        background: $salmon;
        color: #fff;
        box-shadow: none !important;
        text-transform: uppercase;
      }
    }
    &.button {
      background: $salmon;
      color: #fff;
      box-shadow: none !important;
      text-transform: uppercase;
    }
  }

  &.action {
    &.buttons {
      .button {
        background: $actionGreen;
        color: #fff;
        border: 1px solid;
        box-shadow: none !important;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: 600;
      }
      &:hover,
      &:focus {
        background: #fff;
        color: $actionGreen;
      }
    }
    &.button {
      background: $actionGreen;
      color: #fff;
      border: 1px solid;
      box-shadow: none !important;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    &:hover,
    &:focus {
        background: #fff;
        color: $actionGreen;
    }
  }

  &.green {
    &.button {
      background: $actionGreen;
      color: #fff;
      border: 1px solid;
      box-shadow: none;

      &:hover,
      &:focus {
          background: #fff;
          color: $actionGreen;
          border: 1px solid;
      }
    }

    &:hover,
    &:focus {
        background: #fff;
        color: $actionGreen;
        border: 1px solid;
    }
  }

  &.successLight {
    &.button {
        background: $altGreen;
        color: #fff;
        border: 1px solid;

        &:hover,
        &:focus {
            background: #fff;
            color: $altGreen;
            border: 1px solid;
        }
    }

    &:hover,
    &:focus {
        background: #fff;
        color: $altGreen;
        border: 1px solid;
    }
  }
}

.swal-overlay {
  background-color:rgba(77, 80, 162, 0.93) !important;
}

/**
 * Buttons
 */
.ui {
  &.circular {
    &.button {
      background: transparent;

      &:hover {
        .icon {
          &.trash {
              color: #f9746a;
          }
        }
      }
    }
  }
}

.icon-item-container {
  .feature-icon {
    height: 100px;

    img {
      max-height: 66px;
      width: auto;
    }
  }

  h4 {
    font-size: 1.35rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.1rem;
    color: #72808E
  }
} // .icon-item-container

.callout-section {
  display: flex;
  flex-direction: column;
  margin: 4rem auto 4rem;
  text-align:center;
  align-content: center;

  &.small {
      margin: 6rem auto 4rem;
  }

  &.large {
      margin: 12rem auto 8rem
  }

  .callout-title {
    color: $salmon;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: .5rem;
  }

  .callout-subtitle {
      color: theme-color('gray-dark');
      display: block;
      width: 100%;
      font-size: 2.25rem;
      color: #4C4F5A;
      margin-bottom: 1.5rem;
      margin-top: 0;
  }
} // .callout-section

.radio--switch {
  display: flex;
  align-items: center;

  input[type="radio"] {
    display: none;

    &:not(:disabled) ~ label {
      cursor: pointer;
      transition: all .3s;
    }

    &:disabled ~ label {
      color: hsla(150, 5%, 75%, 1);
      border-color: hsla(150, 5%, 75%, 1);
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  label {
    display: block;
    background: white;
    border: 1px solid #dfdfdf;
    color: #747474 !important;
    border-radius: 6px;
    padding: .95rem 1rem .65rem;
    line-height: 1.2 !important;
    text-align: center;
    position: relative;
    cursor: pointer;
    margin-bottom: 1.75rem !important;
    font-size: .95rem !important;

    .label--title {
      display: block;
      width: 100%;
      font-size: 1.35rem;
      font-weight: bold;
      margin-bottom: 1rem;
      margin-top: 3px;
    }

    .label--subtitle {
      display:block;
      width: 100%;
      font-size: 1.1rem;
      color: #72808E !important;
      line-height: 1.75;
      text-transform: initial;
      text-align: left;
    }
  } // label

  input[type="radio"]:checked + label {
    &::after {
      color: $actionGreen;
      font-family: Icons;
      border: 2px solid $actionGreen;
      content: "\f00c";
      font-size: 13px;
      position: absolute;
      top: -16px;
      left: 50%;
      transform: translateX(-50%);
      height: 25px;
      width: 25px;
      line-height: 24px;
      text-align: center;
      border-radius: 50%;
      background: white;
    }

    .label--subtitle {
      color: #fff !important;
    }
  } // label

  @media only screen and (max-width: 700px) {
    section {
      flex-direction: column;
    }
  }
} // .radio--switch



/* print styles */
@media print {
    .sidebar-parent {
        display: none
    }

    #mobile-header {
        .notifications,
        .ui.green.button {
            display: none;
        }
    }

    footer {
        display: none;
    }

    #batch_daily_collected {
        &.ui.tab {

            .four.wide.column.date-picker {
                width: 50% !important
            }
            .ui.cards {
                .description {
                    .ui.stackable.grid {
                        .sixteen.wide.mobile.eight.wide.tablet.four.wide.computer.column.item {
                            width: 25% !important;
                        }
                    }
                }
            }
        }

        .ui.buttons {
            display: none;
        }

        .ui.basic.print.button {
            display: none;
        }
    }
}

.ui.selection.dropdown {
  border-color: #4D50A2
}

.pg.message {
  $icon-width: 1.75em;

  border: 1px solid #CCC;
  border-radius: 5px;
  display: flex;
  position: relative;
  padding: .75em .75em .75em ($icon-width + .75em);
  margin: 1rem 0;

  .icon {
    background-color: #CCC;
    color: #666;
    position: absolute;
    height: 100%;
    width: $icon-width;
    top: 0;
    left: 0;

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -.5em;
      width: $icon-width;
      text-align: center;
    }
  } // .icon

  &.info {
    border-color: #D1ECFF;

    .icon {
      background-color: #D1ECFF;
      color: #1B7FA0;
    }
  }

  &.warning {
    border-color: #F2D13A;
    display: block !important;

    .icon {
      background-color: #F2D13A;
      color: #000;
    }
  }
} // .message

.password-eye {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  translate: -70% -50%;
}

.fit-content {
  max-width: fit-content;
}

//quill text editor
.quillWrapper {
  .ql-toolbar {
      background-color: #fafafa;
  }
  .ql-container {
      background-color: white;
      
      .ql-editor {
          min-height: 5rem;
      }
  }
}