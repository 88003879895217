// Body
$body-bg: #f0f0f5;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
//$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #f9746a;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #00a081;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// custom colors
// alt colors are used for ACCESSIBLE COLORS depending on background color
//$salmon: #f9746a;
//$salmon: #f86459;
$salmonAlt: #df1c0d;
//$indigo: #1f4075;
//$cornblue: #4D50A2;
//$actionGreen: #00A081;
$actionGreen: #00a081;
$cornblue: #4d50a2;
$indigo: #1e4176;
$salmon: #f2746a;
$altGreen: #02d0a8;
$gray: #747474;
$grayAlt: #72808e;
$buttonGray: #5b5b5b;
$lightGray: #bbb;

// text
$heading: $indigo;
$text: $gray;

// marketing site
$theme-colors: (
    blue: #4d50a2,
    indigo: #6610f2,
    purple: #6f42c1,
    pink: #e83e8c,
    red: #dc3545,
    orange: #fd7e14,
    yellow: #ffc107,
    green: #00a081,
    teal: #20c997,
    cyan: #17a2b8,
    white: #fff,
    gray: #72808e,
    gray-dark: #4c4f5a,

    primary: #00a081,

    //BF: this color is not accessible and will need to be changed. leaving currently until I know what my backgrounds are going to be like so I can choose the proper contrast
    secondary: #1f4075,
    highlight: #f2746a,
    success: #00a081,
    info: #1f4075,
    warning: #ffc107,
    danger: #dc3545,
    light: #f8f9fa,
    dark: #414141,
);

$body-color: #333;
$font-color: #72808e;

//$primary: #f9746a; changed to green 11/20
$primary: theme-color("primary");
//$secondary: #1f4075;
$secondary: theme-color("secondary");

$highlight: theme-color("highlight");

$grid-breakpoints: (
    xs: 0,
    sm: 480px,
    md: 640px,
    lg: 992px,
    xl: 1300px,
);

:root {
    --blue: #4d50a2;
    --shadowBackground: #616161e5;
}
